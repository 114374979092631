import styled from 'styled-components';
import { motion } from 'framer-motion';
import Img from "next/legacy/image";
import * as breakpoints from '../abstracts/_breakpoints';
import * as colors from '../abstracts/_variables.styles';
import * as typo from '../abstracts/_typography.styles';

const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const GridContainer_Page_Layout = styled(motion.div)`
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: repeat(3, auto);
	gap: 0px 0px;
	grid-template-areas:
		'header'
		'content'
		'footer';
`;

export const GridItem_HeaderSection = styled.div`
	grid-area: 'header';
	width: 100%;
	height: 10vh;
	z-index: 10;
	position: sticky;
	top: 0;
	left: 0;
	padding: 0rem 0rem;
	margin: 0rem 0rem;
	background: inherit;
`;

export const GridItem_MainSection = styled.div`
	grid-area: 'content';
	width: 100%;
	height: auto;
	background: ${colors.bg_color};
`;

export const Page_Main_Grid_Container = styled.div`
	margin: 0rem 0rem 0rem 0rem;
	padding: 0rem 0rem;
	display: grid;
	grid-gap: 0rem;
	grid-template-rows: 100vh auto auto auto auto auto;
	grid-template-columns: 100%;
	position: absolute;
	top: 0rem;
	left: 0rem;
	width: 100%;
	background: ${colors.warm_palatte_neutral_light};
	@media ${xxSmallDevices} {
		grid-template-rows: auto auto auto auto;
	}
	@media ${xxSmallDevicesLandscape} {
		grid-template-rows: 100vh auto auto auto;
	}
	@media ${extraSmallDevices} {
		grid-template-rows: auto auto auto;
	}
	@media ${extraSmallDevicesLandscape} {
		grid-template-rows: 120vh auto auto auto;
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
		grid-template-rows: auto auto auto auto;
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const GridItem_FooterSection = styled.div`
	grid-area: 'footer';
	padding: 0rem;
	margin: 0rem;
	height: auto;
	z-index: 0;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const GridItem_Section = styled(motion.div)`
	width: 100%;
	height: ${(props) => (props.size === 'small' ? '90vh' : props.size === 'auto' ? 'auto' : props.size === 'large' ? 'auto' : '100vh')};
	position: relative;
	z-index: 1;
	margin: 0rem 0rem;
	padding: 0rem 0rem;

	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
		width: 100%;
		height: 120vh;
	}
	@media ${mediumDevices} {
		height: ${(props) => (props.size === 'small' ? '90vh' : props.size === 'auto' ? 'auto' : props.size === 'large' ? 'auto' : '100vh')};
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		height: ${(props) => (props.size === 'small' ? '90vh' : props.size === 'auto' ? 'auto' : props.size === 'large' ? 'auto' : '100vh')};
	}
	@media ${xxLargeDevices} {
	}
`;

export const H1 = styled(motion.h1)`
	${typo.font_color_P_S_T_W_B}
	${typo.textAlign}
	font-family: 'Editorial New', sans-serif;
	letter-spacing: 0rem;
	margin: 0rem 0rem;
	padding: 0rem 0rem 0rem 0rem;
	font-weight: 300;
	line-height: 1.25;
	cursor: pointer;
	@media ${xxSmallDevices} {
		font-size: ${typo.h1_xxS};
	}
	@media ${extraSmallDevices} {
		font-size: ${typo.h1_xS};
	}
	@media ${mediumDevices} {
		font-size: ${typo.h1_M};
	}
	@media ${largeDevices} {
		font-size: ${typo.h1_L};
	}
	@media ${extraLargeDevices} {
		font-weight: 400;
		font-size: ${typo.h1_xL};
	}
	@media ${xxLargeDevices} {
		font-weight: 400;
		font-size: ${typo.h1_xL};
	}
`;

export const H2 = styled(motion.h2)`
	${typo.font_color_P_S_T_W_B}
	${typo.textAlign}
	font-family: 'Editorial New', sans-serif;
	padding: 0rem 0rem;
	width: 100%;
	letter-spacing: 0.25rem;
	${'' /* line-height: 4rem; */}
	font-weight: 300;
	margin: 0rem;
	@media ${xxSmallDevices} {
		padding: 0rem 0rem;
		font-size: ${typo.h2_xxS};
	}
	@media ${extraSmallDevices} {
		padding: 0rem 0rem;
		font-size: ${typo.h2_xS};
	}
	@media ${extraSmallDevicesLandscape} {
		padding: 0rem 0rem;
		font-size: ${typo.h2_xS};
	}
	@media ${mediumDevices} {
		font-size: ${typo.h2_M};
	}
	@media ${largeDevices} {
		font-size: ${typo.h2_L};
	}
	@media ${extraLargeDevices} {
		font-size: ${typo.h2_xL};
	}
	@media ${xxLargeDevices} {
		font-size: ${typo.h2_xL};
	}
`;

export const H3 = styled(motion.h3)`
	${typo.font_color_P_S_T_W_B}
	${typo.textAlign}
	font-family: 'Editorial New', sans-serif;
	width: 100%;
	height: auto;
	white-space: normal;
	letter-spacing: 0.25rem;
	line-height: 5rem;
	font-weight: 600;
	margin: 0rem 0rem;

	@media ${xxSmallDevices} {
		padding: 0rem 0rem;
		font-size: ${typo.h3_xxS};
		line-height: 1.05;
	}
	@media ${extraSmallDevices} {
		padding: 0.5rem 0rem;
		font-size: ${typo.h3_xS};
	}
	@media ${extraSmallDevicesLandscape} {
		padding: 0rem 2rem 0rem 2rem;
		font-size: ${typo.h3_xS};
	}
	@media ${mediumDevices} {
		font-size: ${typo.h3_M};
		padding: 0rem 0rem;
	}
	@media ${mediumDevicesLandscape} {
		font-size: ${typo.h3_S};
		padding: 0rem 2rem 0rem 2rem;
	}
	@media ${largeDevices} {
		font-size: ${typo.h3_L};
		padding: 0rem 0rem;
		line-height: 5rem;
	}
	@media ${extraLargeDevices} {
		font-size: ${typo.h3_xL};
	}
	@media ${xxLargeDevices} {
		font-size: ${typo.h3_xL};
	}
`;

export const H4 = styled(motion.h4)`
	${typo.font_color_P_S_T_W_B}
	${typo.textAlign}
	font-family: 'Editorial New', sans-serif;
	width: 100%;
	margin: 0rem 0rem;
	letter-spacing: 0.4rem;
	font-weight: 300;
	line-height: 1.2;
	padding: 0rem 0rem;
	@media ${xxSmallDevices} {
		font-size: ${typo.h4_xxS};
	}
	@media ${extraSmallDevices} {
		font-size: ${typo.h4_xS};
	}
	@media ${mediumDevices} {
		font-size: ${typo.h4_M};
	}
	@media ${largeDevices} {
		font-size: ${typo.h4_L};
	}
	@media ${extraLargeDevices} {
		font-size: ${typo.h4_xL};
	}
	@media ${xxLargeDevices} {
		font-size: ${typo.h4_xL};
	}
`;

export const H5 = styled(motion.h4)`
	${typo.font_color_P_S_T_W_B}
	${typo.textAlign}
	font-family: 'Editorial New', sans-serif;
	width: 100%;
	margin: 0rem 0rem;
	letter-spacing: 0.4rem;
	font-weight: 300;
	line-height: 1.2;
	padding: 0rem 0rem;
	@media ${xxSmallDevices} {
		font-size: ${typo.h5_xxS};
	}
	@media ${extraSmallDevices} {
		font-size: ${typo.h5_xS};
	}

	@media ${mediumDevices} {
		font-size: ${typo.h5_M};
	}
	@media ${largeDevices} {
		font-size: ${typo.h5_L};
	}
	@media ${extraLargeDevices} {
		font-size: ${typo.h5_xL};
	}
	@media ${xxLargeDevices} {
		font-size: ${typo.h5_xL};
	}
`;
export const StyleP = styled(motion.div)`
	${typo.font_color_P_S_T_W_B}
	font-family: 'NimbusSans', sans-serif;
	width: 100%;
	line-height: 1.2;
	letter-spacing: 0.1rem;
	margin: 0rem 0rem;
	padding: 0.5rem 0rem;
	${typo.textAlign}
	@media ${xxSmallDevices} {
		font-size: ${typo.p_xxS};
	}
	@media ${extraSmallDevices} {
		font-size: ${typo.p_xS};
	}
	@media ${extraSmallDevicesLandscape} {
		font-size: ${typo.p_xS};
	}
	@media ${mediumDevices} {
		line-height: 1.25;
		padding: 1rem 0rem;
		font-size: ${typo.p_M};
	}
	@media ${mediumDevicesLandscape} {
		line-height: 1.25;
		font-size: ${typo.p_M};
	}
	@media ${largeDevices} {
		font-size: ${typo.p_L};
	}
	@media ${extraLargeDevices} {
		font-size: ${typo.p_xL};
	}
	@media ${xxLargeDevices} {
		line-height: 1.35;
		letter-spacing: 0.1rem;
		font-size: ${typo.p_xL};
	}
`;

export const MenuP = styled(motion.p)`
	height: auto;
	font-size: 2rem;
	width: 100%;
	cursor: pointer;
	text-align: center;
	display: none;
	@media ${extraSmallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${largeDevices} {
		display: inline-block;
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;
export const MenuPBookNow = styled(motion.p)`
	height: auto;
	font-size: 2rem;
	width: 100%;
	cursor: pointer;
	text-align: center;
	display: inline-block;
	@media ${extraSmallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${largeDevices} {
		display: inline-block;
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const PortableTextWrapper = styled(motion.div)`
	${typo.font_color_P_S_T_W_B}
	${typo.textAlign}
	font-family: 'NimbusSans', sans-serif;
	width: 100%;
	line-height: 1.25;
	letter-spacing: 0.1rem;
	margin: 0rem 0rem;
	padding: 1rem 2rem;

	@media ${xxSmallDevices} {
		padding: 0rem 2rem;
		font-size: ${typo.p_xxS};
	}
	@media ${extraSmallDevices} {
		text-align: left;
		padding: 0rem 2rem;
		font-size: ${typo.p_xS};
	}
	@media ${extraSmallDevicesLandscape} {
		text-align: left;
		padding: 0rem 2rem;
		font-size: ${typo.p_xS};
	}
	@media ${mediumDevices} {
		text-align: left;
		line-height: 1.25;
		padding: 0rem 2rem;
		font-size: ${typo.p_M};
	}
	@media ${mediumDevicesLandscape} {
		text-align: left;
		line-height: 1.25;
		padding: 0rem 2rem;
		font-size: ${typo.p_M};
	}
	@media ${largeDevices} {
		font-size: ${typo.p_L};
		padding: 0rem 4rem 0rem 4rem;
	}
	@media ${extraLargeDevices} {
		font-size: ${typo.p_xL};
	}
	@media ${xxLargeDevices} {
		text-align: left;
		line-height: 1.35;
		letter-spacing: 0.1rem;
		font-size: ${typo.p_xL};
		${'' /* font-weight: 200; */}
	}
`;

export const SmallP = styled(motion.p)`
	${typo.font_color_P_S_T_W_B}
	${typo.textAlign}
	font-family: 'NimbusSans', sans-serif;
	font-weight: 200;
	letter-spacing: 0.25rem;
	line-height: 1.2;
	padding: 0rem;
	margin: 0rem;
	@media ${xxSmallDevices} {
		font-size: ${typo.smallP_xxS};
	}
	@media ${extraSmallDevices} {
		font-size: ${typo.smallP_xS};
	}
	@media ${mediumDevices} {
		font-size: ${typo.smallP_M};
	}
	@media ${largeDevices} {
		font-size: ${typo.smallP_L};
	}
	@media ${extraLargeDevices} {
		font-size: ${typo.smallP_xL};
	}
	@media ${xxLargeDevices} {
		font-size: ${typo.smallP_xL};
		text-align: left;
	}
`;

export const XSmallP = styled(motion.p)`
	${typo.font_color_P_S_T_W_B}
	${typo.textAlign}
	font-family: 'NimbusSans', sans-serif;
	font-weight: 200;
	letter-spacing: 0rem;
	line-height: 1.2;
	padding: 0rem;
	margin: 0rem;
	@media ${xxSmallDevices} {
		font-size: ${typo.xsmallP_xxS};
	}
	@media ${extraSmallDevices} {
		font-size: ${typo.xsmallP_xS};
	}
	@media ${mediumDevices} {
		font-size: ${typo.xsmallP_M};
	}
	@media ${largeDevices} {
		font-size: ${typo.xsmallP_L};
	}
	@media ${extraLargeDevices} {
		font-size: ${typo.xsmallP_xL};
	}
	@media ${xxLargeDevices} {
		font-size: ${typo.xsmallP_xL};
		text-align: left;
	}
`;

export const ImgCover = styled(Img)`
	${'' /* border: 0.25rem solid black; */}
	object-fit: cover;
	layout: fill;
	margin-left: 0%;
	object-position: center bottom;
	border-radius: 0%;
	min-width: 10% !important;
	min-height: 100% !important;
	width: 100% !important;
	height: 100% !important;
	margin-left: 0% !important;
	margin-top: 0vh !important;

	@media ${xxSmallDevicesLandscape} {
	}
	@media ${xxSmallDevices} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const SmallImage = styled(motion.img)`
	width: auto;
	height: 6rem;
	object-fit: cover;
	object-position: right bottom;
	transform: translate(0%, 0%);
	border-radius: 0rem;
	padding: 0rem 0rem;
	margin: auto auto;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
		height: 8rem;
	}
	@media ${mediumDevicesLandscape} {
		height: 8rem;
	}
	@media ${largeDevices} {
		height: 8rem;
	}
	@media ${extraLargeDevices} {
		height: 8rem;
	}
	@media ${xxLargeDevices} {
		height: 10rem;
	}
`;

export const SmallIcon = styled(motion.img)`
	width: auto;
	height: 2rem;
	object-fit: cover;
	object-position: right bottom;
	transform: translate(0%, 0%);
	border-radius: 0rem;
	padding: 0rem 0rem;
	margin: auto auto;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
		height: 2.5rem;
	}
	@media ${mediumDevicesLandscape} {
		height: 3rem;
	}
	@media ${largeDevices} {
		height: 3rem;
	}
	@media ${extraLargeDevices} {
		height: 3.5rem;
	}
	@media ${xxLargeDevices} {
		height: 4rem;
	}
`;

export const CenterDiv = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 0rem;
	height: 100%;
	position: relative;
	@media ${xxSmallDevices} {
		height: 100%;
		padding: 0rem 0rem;
	}
	@media ${extraSmallDevices} {
		height: 100%;
	}
	@media ${mediumDevices} {
		height: 100%;
		justify-content: center;
	}
	@media ${largeDevices} {
		height: 100%;
		justify-content: center;
	}
	@media ${extraLargeDevices} {
		height: 100%;
		justify-content: center;
	}
	@media ${xxLargeDevices} {
		height: 100%;
		justify-content: center;
	}
`;

export const H1TextDiv = styled(motion.div)`
	position: relative;
	z-index: 1;
	left: 50%;
	transform: translateX(-50%);
	top: 45%;
	width: 55%;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
		top: 40%;
		width: 65%;
	}
	@media ${extraLargeDevices} {
		top: 35%;
		width: 90%;
	}
	@media ${xxLargeDevices} {
		top: 37%;
	}
`;

export const H1TextDivIndex = styled(motion.div)`
	position: relative;
	z-index: -100;
	left: 50%;
	transform: translateX(-50%);
	top: 45%;
	width: 55%;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
		top: 40%;
		width: 65%;
	}
	@media ${extraLargeDevices} {
		top: 35%;
		width: 90%;
	}
	@media ${xxLargeDevices} {
		top: 37%;
	}
`;

export const H3TextDiv = styled(motion.div)`
	position: absolute;
	z-index: 1;
	left: 50%;
	transform: translateX(-50%);
	top: 40%;
	width: auto;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
		width: auto;
	}
	@media ${extraLargeDevices} {
		width: auto;
	}
	@media ${xxLargeDevices} {
	}
`;

export const BookingDiv = styled(motion.div)`
	position: absolute; // or 'fixed' if you want to position relative to the viewport
	z-index: 10;
	cursor: pointer;
	height: 5rem;
	width: 6rem;
	// Centering horizontally
	left: 45%;
	transform: translateX(-50%);
	// Positioning 70% from the top
	top: 75%;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
		left: 48%;
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
		position: relative;
		z-index: 1;
		cursor: pointer;
		height: 5rem;
		left: 48%;
		transform: translateX(-50%);
		width: 7rem;
		top: 75%;
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
		position: relative;
		z-index: 1;
		cursor: pointer;
		height: 5rem;
		left: 48%;
		transform: translateX(-50%);
		width: 7rem;
		top: 75%;
	}
`;

export const WhatsAppDiv = styled(motion.div)`
	position: fixed;
	z-index: 100;
	cursor: pointer;
	height: 3rem;
	width: 3rem;
	right: 3.5rem; // Position 1rem from the right edge
	bottom: 3rem; // Position 1rem from the bottom edge

	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
		height: 5rem;
		width: 5rem;
	}
	@media ${extraLargeDevices} {
		height: 5rem;
		width: 5rem;
	}
	@media ${xxLargeDevices} {
		height: 5rem;
		width: 5rem;
	}
`;

export const AccreditationsGrid = styled(motion.div)`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 100%;
	margin: auto 0rem;
	width: 95%;
	gap: 1rem;
	@media ${xxSmallDevices} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
		margin: 2rem 0rem;
	}
	@media ${largeDevices} {
		width: 65%;
		margin: 2rem 0rem;
	}
	@media ${extraLargeDevices} {
		width: 65%;
	}
	@media ${xxLargeDevices} {
		width: 65%;
	}
`;

export const BookingFormDiv = styled(motion.div)`
	z-index: 0;
	position: absolute;
	cursor: pointer;
	height: 5rem;
	left: 50%;
	transform: translateX(-50%);
	width: 90%;
	bottom: 10rem;
	${'' /* top: 80%; */}
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
		height: 3rem;
		left: 50%;
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
		position: absolute;
		cursor: pointer;
		height: 5rem;
		left: 50%;
		transform: translateX(-50%);
		width: 85%;
		bottom: 10rem;
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
		position: absolute;
		cursor: pointer;
		height: 5rem;
		left: 50%;
		transform: translateX(-50%);
		width: 85%;
		bottom: 10rem;
	}
`;
